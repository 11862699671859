
html, body {
    height: 100%;
    margin: 0;
}

body {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    padding: 10px 120px;
    background-color: #d3d3d3;
}

#content {
    flex: 1 0 auto;
    position: relative;

    h1 {
        font-size: 48px;
        font-weight: 500;
        text-align: center;
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: #fff 1px solid;
        color: rgba(#000, 0.7);
        text-shadow: -1px -1px 2px #fff,  
                      1px -1px 2px #fff,
                     -1px 1px 2px #fff,
                      1px 1px 2px #fff;
    }
        
    ul {
        list-style: none;
        padding: 0;

        li {
            font-size: 20px;
        }

        &#resource-nav {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }
    }

    h2 {
        font-weight: 500;
    }

    p {
        font-size: 18px;
    }

    #back-to {
        position: absolute;
        bottom: 20px;
    }
}

footer {
    flex-shrink: 0;
    font-size: 10px;
}